import React from 'react'
import styled from 'styled-components'

const ThankYouRegistrationPage = () => {
  return (
    <StyledThankYouRegistrationPage>
      <h1>Thank you!</h1>
      <p>Your registration has been successfully submitted. Our team will review your application to ensure you meet the course entry requirements. Should you not qualify for your chosen course, your payment will be refunded. You will receive a confirmation email within 24 hours.</p>
      <p>We look forward to engaging with you further on your studies with UniCollege.</p>
    </StyledThankYouRegistrationPage>
  )
}

const StyledThankYouRegistrationPage = styled.main`
  max-width: 1500px;
  margin: auto;
  padding: 6rem 2rem;

  h1 {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
  }
`

export default ThankYouRegistrationPage
